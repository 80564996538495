<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmTier')" data-vv-scope="frmTier" novalidate="novalidate">
    <div class="row justify-content-md-center">

      <div class="col-12 col-md-12">
        <!--  Geral -->
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierArmador.section.general') }}</h5>
            <div class="row">
                <armador-field
                  v-model="tierArmador.idarmador"
                  rootClassName="col-12 col-sm-8 form-group mb-4"
                />

                <custom-input
                  v-model="tierArmador.codigo"
                  name="tierArmador.codigo"
                  :label="this.$i18n.t('form.tierArmador.codigo')"
                  type="text"
                  rootClassName="col-12 col-sm-2 form-group mb-4"
                  :max-length="25"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('tierArmador.codigo')">
                </custom-input>

                <div class="col-12 col-sm-2 form-group mb-4">
                  <label>{{ this.$i18n.t('form.tierArmador.tipoOperacao') }}</label>
                  <v-select name="tierArmador.tipoOperacao" label="tierArmador.tipoOperacao" :clearable="false" :searchable="true" v-model="tierArmador.tipoOperacao"  :options="tipoOperacaoList">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
          </div>
        </div>

        <!-- Frete -->
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierArmador.section.frete') }}</h5>
            <div class="row">
              <div class="col-sm-4 form-group mb-4">
                <label>
                  {{ this.$i18n.t('form.tierArmador.freteMoeda') }}
                  <span><i class="ft-alert-circle"></i>
                    <md-tooltip md-direction="right">Será utilizada a mesma moeda do frete</md-tooltip>
                  </span>
                </label>
                <v-select :disabled="true" name="nome" label="nome" :clearable="false" :searchable="true" v-model="moeda" >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
              <custom-input
                v-model="tierArmador.freteValor"
                name="tierArmador.freteValor"
                :label="this.$i18n.t('form.tierArmador.freteValor')"
                type="text"
                rootClassName="col-sm-4 form-group mb-4"
                :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                :error="submitted && errors.first('tierArmador.freteValor')">
              </custom-input>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveTier">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>

    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import TierService from '@/services/TierService'
import ArmadorField from '@/components/Forms/ArmadorField'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.tier.title') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      formErrors: {},
      isLoading: true,
      fullPage: true,
      moeda: {
        id: null,
        nome: 'Moeda do frete'
      },
      // List of options to select on select input
      tipoOperacaoList: [
        {
          id: 1,
          nome: 'Exportação'
        },
        {
          id: 2,
          nome: 'Importação'
        }
      ],
      //  Selected Values (Form)
      tierArmador: {
        codigo: null,
        api: true,
        tipoOperacao: null,
        freteValor: null,
        idarmador: null
      },
      submitted: false
    }
  },
  components: {
    ArmadorField,
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data

    // If is edit populate values
    let tierId = this.$route.params.id_tier

    if (tierId) {
      TierService.getTierArmador(tierId).then(tier => {
        _this.tierArmador = tier.data
        _this.tierArmador.tipoOperacao = _this.tipoOperacaoList.find(function (x) {
          return x.id === _this.tierArmador.tipoOperacao
        })
      }).finally(() => {
        _this.isLoading = false
      })
    }
    // End edit
    _this.isLoading = false
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TierArmadorIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onSaveTier (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let tierData = {
            codigo: this.tierArmador.codigo,
            api: this.tierArmador.api,
            freteValor: this.tierArmador.freteValor,
            tipoOperacao: this.tierArmador.tipoOperacao.id,
            idarmador: this.tierArmador.idarmador.id
          }

          let tierId = this.$route.params.id_tier
          if (tierId) {
            TierService.editTierArmador(tierId, tierData).then(response => {
              _this.$router.push({ name: 'MaerskTierArmadorIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TierService.newTierArmador(tierData).then(response => {
              _this.$router.push({ name: 'MaerskTierArmadorIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>
